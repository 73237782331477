<template>
  <b-card

    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Auxiliares Sin Aprobador</b-card-title>

    </b-card-header>

    <b-card-body>
      <b-row>
        <b-col
          v-for="(xaprobadores, i) in data"
          :key="i"
          class="transaction-item"
          md="3"
        >
          <div>
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  rounded
                  size="42"
                  variant="light-primary"
                >
                  <feather-icon
                    size="18"
                    icon="userIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="transaction-title">
                  {{ xaprobadores.idCentro }} - {{ xaprobadores.unidad }}
                </h5>
                <small>{{ xaprobadores.descripcion }}</small>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
      </b-row>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BRow, BCol
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BRow,
    BCol
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
<style>
.transaction-item{
  border-bottom: 1px solid #f2f2f2;
  padding: 2px 0;
}
</style>

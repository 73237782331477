<template>
  <section
    v-if="DashData"
    id="dashboard-ecommerce"
  >
    <b-row>
      <!-- Revenue Report Card -->
      <b-col lg="12">
        <periods
          :data="DashData.periodos"
          :datax="DashData.periodos"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="12">
        <auxilary
          :data="DashData.auxiliares"
          :centros="centrosFinal"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <chartjs-bar-chart />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardBody,
} from 'bootstrap-vue'

import Auxilary from '@/views/cda/dashboards/Auxilary.vue'
import AuxilaryWithout from '@/views/cda/dashboards/AuxilaryWithout.vue'
import CostCenters from '@/views/cda/dashboards/CostCenters.vue'
import PreactiveAccounts from '@/views/cda/dashboards/PreactiveAccounts.vue'
import environment from '@/environment'
import Periods from './dashboards/Periods.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import chartjsBarChart from "@/views/cda/charts/ChartjsBarChart.vue"

export default {
  components: {
    BRow,
    BCol,
    Periods,
    Auxilary,
    chartjsBarChart
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('userData')),
      DashData: '',
      auxilaresCentros: '',
      auxiliaresEstados: '',
      statesFinal: [],
      centrosFinal: [],
      centrosStore: []
    }
  },
  async created() {
    await this.getDashboardData()
    await this.getCentrosData()
    this.getEqualData(this.centrosStore, this.DashData.auxiliares)

  },
  methods:{
    async getDashboardData(){
      await this.$http.get(`${environment.uri}/workflows/dashboard`)
          .then(response => {
            this.DashData = response.data
          })
    },
    async getCentrosData(){
      await this.$http.get(`${environment.uri}/ccenters/all`)
          .then(response => {
            this.centrosStore = response.data
          })
    },
    getEqualData(rData, fData) {
      let res = []
      res = rData.filter(el => {
        return fData.find(element => {
          return element.idCentro === el.idCentro
        })
      })
      const a = {label: 'Todos', idCentro: 'all'}
      res.unshift(a)
      this.centrosFinal = res
      return res
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.lead .collapse-title{
  font-size: 1.8em;
}
.custom-collapsed{
  font-size: 1.1em;
}
</style>

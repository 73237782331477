<template>
  <div>
    <b-card
      v-if="data"
      class="card-transaction"
      no-body
    >
      <b-card-header>
        <b-card-title>Estado de Auxiliares</b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="text-right">
          <b-row
            align-h="end"
            md="12"
            class="mb-2"
          >
            <b-col
              class="mr-2"
              md="4"
            >

              <b-form-select
                v-model="selectedCenter"
                :options="centros"
                text-field="label"
                value-field="idCentro"
                size="sm"
                class="mt-1"
                @change="filterCenter(selectedCenter, selectedState)"
              />
            </b-col>
            <b-col md="2">
              <b-form-select
                v-model="selectedState"
                :options="states"
                text-field="status"
                value-field="idStatus"
                size="sm"
                class="mt-1"
                @change="filterCenter(selectedCenter, selectedState)"
              />
            </b-col>
          </b-row>
        </div>
        <b-row class="p-1">
          <b-col
            v-for="auxilaries in datax"
            :key="auxilaries.id"
            md="4"
            class="transaction-item"
          >
            <div>
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="userIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="transaction-title">
                    {{ auxilaries.idCentro }} - {{ auxilaries.unidad }} | {{ auxilaries.TIPO }}<span class="font-small-2 text-primary" />
                  </h5>
                  <small>{{ auxilaries.descripcion }} - {{ auxilaries.ACTUALIZADO }}</small>
                </b-media-body>
              </b-media>
              <div style="padding-left: 56px;">
                <h5
                  v-if="auxilaries.status==='P'"
                  class="text-warning"
                >
                  Pendiente
                </h5>
                <h5
                  v-if="auxilaries.status==='C'"
                  class="text-success"
                >
                  Cerrado
                </h5>
                <h5
                  v-if="auxilaries.status==='D'"
                  class="text-secondary"
                >
                  Cargado
                </h5>
                <h5
                  v-if="auxilaries.status==='V'"
                  class="text-success"
                >
                  Validado
                </h5>
              </div>
            </div>
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
  </div>

</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BCol,
  BRow,
} from 'bootstrap-vue'
import { BFormSelect } from 'bootstrap-vue'
import environment from "@/environment"
import {arrayify} from "vue-flatpickr-component/src/util"

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BFormSelect,
    BCol,
    BRow
  },
  props: [
    // eslint-disable-next-line vue/require-prop-types
    'data',
    // eslint-disable-next-line vue/require-prop-types
    'estados',
    // eslint-disable-next-line vue/require-prop-types
    'centros'
  ],
  data(){
    return{
      selectedState: 'all',
      selectedCenter: 'all',
      centerResult: '',
      datax: '',
      states:[
        {
          idStatus: 'all',
          status: 'Todos'
        },
        {
          idStatus: 'V',
          status: 'Validado'
        },
        {
          idStatus: 'P',
          status: 'Pendiente'
        },
        {
          idStatus: 'D',
          status: 'Cargado'
        }
      ],
    }
  },
   created() {
    this.datax = this.data
  },
  methods: {
    filterCenter(center, status){
      let result = this.data

      if (center !== 'all'){
        result = result.filter(val => val.idCentro === center)
      }

      if (status !== 'all'){
        result = result.filter(val => val.status === status)
      }
      if(center === 'all' && status === 'all'){
        result = this.data
      }
      this.datax = result
      console.log('result ', result)
    },
  }
}
</script>
<style>
.transaction-item{
  border-bottom: 1px solid #f2f2f2;
  padding: 2px 0;
}
</style>

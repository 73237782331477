<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Cuentas Preactivas</b-card-title>

    </b-card-header>

    <b-card-body>
      <div
        v-for="cuentas in data"
        :key="cuentas.id"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="light-primary"
            >
              <feather-icon
                size="18"
                icon="DollarSignIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h5 class="transaction-title">
              {{ cuentas.idCuenta }}
            </h5>
            <small>{{ cuentas.cuenta }}</small>

          </b-media-body>
        </b-media>
        <div class="text-right">
          {{ cuentas.idCentro }} {{ cuentas.centro }}
        </div>
        <div>
          <h5
            v-if="cuentas.status==='P'"
            class="text-warning"
          >
            Preactivo
          </h5>
          <h5
            v-if="cuentas.status==='A'"
            class="text-secondary"
          >
            Activa
          </h5>
          <h5
            v-if="cuentas.status==='C'"
            class="text-success"
          >
            Cerrada
          </h5>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
<style scoped>
.transaction-item{
  border-bottom: 1px solid #f2f2f2;
  padding: 11px 0;
}
.media{
  min-width: 34% !important;
}
</style>

<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Histórico de Rentabilidad</b-card-title>
      <!-- datepicker -->
<!--      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>-->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <b-row class="">
        <b-col cols="1" class="p-1">
          <b-button class="btn p-2 btn-icon" variant="outline-secondary" >
            <Icon icon="ph:building-office" class="graphIcon" />
            Activos
          </b-button>
        </b-col>
        <b-col cols="1"  class="p-1">
          <b-button class="btn p-2 btn-icon" variant="outline-secondary" >
            <Icon icon="iconoir:coins" class="graphIcon" />
            Pasivos
          </b-button>
        </b-col>
      </b-row>
      <chartjs-component-bar-chart
          class="mt-3"
        :height="400"
        :data="chartjsData.latestBarChart.data"
        :options="chartjsData.latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BButton
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './ChartjsComponentBarChart.vue'
import chartjsData from './chartjsData'
import { Icon } from '@iconify/vue2'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BButton,
    ChartjsComponentBarChart,
    Icon
  },
  data() {
    return {
      chartjsData,
      rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.graphIcon{
  font-size: 36px;
  stroke-width: 1px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: .5rem;
}
</style>

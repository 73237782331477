<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Centros de Costo en Conflicto</b-card-title>

    </b-card-header>

    <b-card-body>
      <div
        v-for="centers in data"
        :key="centers.idCentro"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="24"
              variant="light-primary"
            >
              <feather-icon
                size="18"
                icon="HomeIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h5 class="transaction-title">
              {{ centers.idCentro }} - {{ centers.unidad }}
            </h5>
          </b-media-body>
        </b-media>
        <div>
          <h5
            class="text-primary"
          >
            Preactivo
          </h5>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
<style scoped>
.transaction-item{
  border-bottom: 1px solid #f2f2f2;
  padding: 11px 0;
}
</style>

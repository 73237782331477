<template>

  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Estados de Periodos</b-card-title>
      <b-dropdown
          variant="link"
          no-caret
          right
          class="chart-dropdown"
          toggle-class="p-0"
      >
        <template #button-content>
          <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item @click="getYearPeriod('all')">
          Todos
        </b-dropdown-item>
        <b-dropdown-item @click="getYearPeriod('2023')">
          2023
        </b-dropdown-item>
        <b-dropdown-item @click="getYearPeriod('2022')">
          2022
        </b-dropdown-item>
        <b-dropdown-item @click="getYearPeriod('2021')">
          2021
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          v-for="(periods, index) in datax"
          :key="periods.periodo"
          md="4"
        >
          <div
            v-if="index <= 11"
            class="transaction-item mb-1"
            style="border:none;"
          >
            <b-media no-body>
              <b-media-aside>
                <feather-icon
                    size="18"
                    icon="CalendarIcon"
                />
              </b-media-aside>
              <b-media-body>
                <h5 class="transaction-title">
                  {{ periods.periodo }}
                </h5>
              </b-media-body>
            </b-media>
            <div style="margin-left: auto;">
              <b-button
                v-if="periods.status==='C'"
                size="sm"
                variant="outline-secondary"
                class="ml-2"
                @click="generateFile(periods.periodo)"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                Descargar
              </b-button>
<span class="text-success" v-if="periods.status==='A'">Abierto</span>
            </div>



          </div>
        </b-col>
      </b-row>


    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
  BButton, BDropdown, BDropdownItem, BRow, BCol
} from 'bootstrap-vue'
import environment from "@/environment"


export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BButton,
    BDropdown,
    BDropdownItem,
    BRow, BCol
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    datax: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filename: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      periodResults: '',
      selected: 'Todos',
      loading: false
    }
  },
  methods: {
    getYearPeriod(e){
      if (e === 'all'){
        const result = this.data
        this.datax = result
        this.selected = 'Todos'
      } else {
        const result = this.data.filter(periods => periods.anio === e)
        this.datax = result
        this.selected = e
        console.log('result ', this.periodResults)
      }
    },
    async load(period) {
      this.errorMsg = 'Un Momento...'
      this.loading = true
      try {
        const resp = await this.$http.get(`${environment.uri}/profits/distributed/id/${period}`)
        const periodsResp = await this.$http.get(`${environment.uri}/workflows/periods`)

        if (periodsResp && periodsResp.data) {
          this.periods = periodsResp.data.filter(p => p.status !== 'P')
          // this.period = this.periods[0].value
        }

        if (resp && resp.data) {
          this.filename = resp.data
          console.log('filename: ', resp.data)
          this.errorMsg = ''
        } else {
          this.filename = ''
          this.errorMsg = 'No existen una distribución de movimientos asociado a las cuentas contables del periodo VARPERIODO, Para efectuar el cálculo debe presionar el botón "Actualizar Distribución".'
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async generateFile(period) {
      this.errorMsg = 'Un Momento...'
      this.loading = true
      try {
        const resp = await this.$http.get(`${environment.uri}/profits/distributed/id/${period}`)

        if (resp && resp.data) {
          this.filename = resp.data
          const link = document.createElement('a')
          link.href = this.filename
          link.download = 'Reporte_Rentabilidad_Distribuida-'+period+'.xlsx'

          // Simulate a click on the element <a>
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          console.log('Generated Filename: ', resp.data)
          this.errorMsg = ''
        } else {
          this.filename = ''
          this.errorMsg = 'No existen una distribución de movimientos asociado a las cuentas contables del periodo VARPERIODO, Para efectuar el cálculo debe presionar el botón "Actualizar Distribución".'
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
<style>
.transaction-item {
  border-bottom: 1px solid #f2f2f2;
}
</style>
